<template>
  <div class="User">
    <transition name="fade" mode="out-in">
      <alert v-if="alertOpen" :content="alertContent" @confirm="closeAlert" />
    </transition>
    <div class="panel">
      <div class="Grid-row">
        <div class="header-title">Restrict word</div>
      </div>
      <div class="Grid-row">
        <div class="Grid-column-4">
          <input
            v-model="restricted_word"
            :class="{ 'has-error': !restricted_word && error }"
            class="input"
            type="text"
            placeholder="Type in restricted word"
          />
        </div>
      </div>
      <div class="Grid-row">
        <button class="button button-success" @click="restrictWord">
          Restrict word
        </button>
      </div>

      <hr />

      <banned-words-list></banned-words-list>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/common/navigation/Alert';
import BannedWordsList from '@/components/ban-tools/words/BannedWordsList';

export default {
  name: 'Word',
  components: {
    Alert,
    BannedWordsList,
  },
  data() {
    return {
      error: false,
      alertOpen: false,
      alertContent: null,
      restricted_word: null,
    };
  },
  methods: {
    restrictWord() {
      if (!this.restricted_word) {
        this.error = true;
      } else {
        this.$store
          .dispatch('restrictWord', { word: this.restricted_word })
          .then(() => {
            this.alertContent = 'Word has been restricted.';
            this.alertOpen = true;
          })
          .catch(() => {
            this.alertContent =
              'There were errors processing request. Please try again.';
            this.alertOpen = true;
          });
      }
    },
    closeAlert() {
      this.alertOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/ban-tools/user/User.scss';
</style>
