<template>
  <div class="Alert">
    <div class="Alert__dialog-content">
      <div class="Alert__dialog-body">{{ content }}</div>
      <div class="Alert__footer">
        <button class="button button-success" type="text" @click="confirm()">
          {{ ok }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    content: {
      type: String,
      required: true,
    },
    ok: {
      type: String,
      default: 'Ok',
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/common/navigation/Alert.scss';
</style>
