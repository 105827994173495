<template>
  <div class="Grid-row">
    <div class="Grid-column-12">
      <table>
        <thead>
          <tr>
            <th>Pattern</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in bannedWords" :key="record.id">
            <td>{{ record.word }}</td>
            <td>
              <button
                class="button button-danger"
                @click="removeRestriction(record)"
              >
                Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannedWordsList',
  computed: {
    bannedWords: {
      get() {
        return this.$store.getters.bannedWords;
      },
    },
  },
  mounted() {
    this.$store.dispatch('getRestrictedWords');
  },
  methods: {
    removeRestriction(record) {
      this.$store.dispatch('removeRestrictedWord', { id: record.id });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/ban-tools/community/SpacesToBan.scss';
</style>
